import logo from './logo.svg';
import './App.css';
import Login from './user/Login';
import MyProfile from './user/MyProfile';
import useToken from "./user/useToken";
import ClaimFiling from './user/ClaimFiling';
import { Routes,Route } from 'react-router-dom';
import jsonData from "./dataStorage/PolicyHolderInfo.json";
import Aviva from './user/AvivaLoginPage';


function App() {
 /*  const { token, setToken } = useToken();
  if (!token) {
    return <Login setToken={setToken} />
  } */
  return (
    <>
    <Routes>
     <Route path ="/" element ={<Login/>}/>
     <Route path="/Aviva" element={<Aviva name="Aviva"/>}/>
      <Route path ='/myprofile' element ={<MyProfile/>}/>
      <Route path ='/claimFiling' element ={<ClaimFiling/>}/>
    </Routes>
    </>
  );
}

export default App;
