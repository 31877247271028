import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { FormControl, Grid, ListItemText, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from '@mui/material/Select';
import logo from '../images/insurance-world-logo.png';
import avivaLogo from '../images/Aviva_Logo.jpg';
import dlgLogo from '../images/DLG_Logo.jpg';
import zebraLogo from '../images/Zebra_Logo.jpg';
import barkLogo from '../images/Bark_Logo.jpg'
import back from '../images/back.png';

export const Header2 = ({ title, subtitle,themeColor, company }) => {
    
   // const [theme, setTheme] = useState('Corelogic');

    /*const changeTheme = (event) => {
        setThemeColor(themeColor);
    }*/
    
    function loadIcon(data) {
        const icons = {
           ['Insurance-world']: <img src={logo} style={{height:30, width:150}}/>,
           ['Aviva']: <img src={avivaLogo} style={{height:30, width:150}}/>,
           ['Bark']: <img src={barkLogo} style={{height:30, width:150}}/>,
           ['DLG']: <img src={dlgLogo} style={{height:30, width:150}}/>,
           ['Zebra']: <img src={zebraLogo} style={{height:30, width:150}}/>,
        }
        return data != undefined ? icons[data] : icons['Insurance-world']
     }

    return (
        <>
            <Box sx={{
                color: '#C8CFD3',
                p: 2,
            }}>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"  >
                    <Grid item xs={2} sx={{ verticalAlign: 'center' }}>
                        <img src={back} style={{ cursor: "pointer" }}  />
                    </Grid>
                     <Grid item xs={8} sx={{ textAlign: 'center', verticalAlign: 'center' }}>
                        {/* {loadIcon(company)} */}
                    </Grid> 
                    <Grid item xs={2} sx={{ textAlign: 'right', verticalAlign: 'center' }}>

                    </Grid>
                </Grid>
            </Box>

            <Box
                component="div"
                sx={{
                    overflow: 'hidden',
                    p: 2,
                    paddingTop: 0,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    textAlign: 'center'
                }}
            >
                <ListItemText primaryTypographyProps={{ fontSize: 20, color: '#C8CFD3' }}
                    secondaryTypographyProps={{ fontSize: 15, color: '#C8CFD3', }}
                    primary={title} secondary={subtitle} />
            </Box>
            <Box
                component="div"
                sx={{
                    overflow: 'hidden',
                    p: 2,
                    paddingTop: 0,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    textAlign: 'center'
                }}
            >
                
            </Box>
        </>

    );

}

Header2.propTypes = {
    /**
     * Is this the principal call to action on the page?
     */
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

Header2.defaultProps = {
    title: '',
    subtitle: '',
}
