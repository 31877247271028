import React, { useState } from "react";
import axios from "axios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import {
  Box,
  ListItemText,
  Paper,
  ThemeProvider,
  colors,
  createTheme,
} from "@mui/material";
import { Header2 } from "../stories/Header2";
import { useNavigate } from "react-router-dom";
import jsonData from "../dataStorage/PolicyHolderInfo.json";
import MyProfile from "./MyProfile";

import CompanySelect from "../components/CompanySelect";

async function loginUser(credentials) {
  const API = axios.create({
    baseURL: "https://localhost:5000/",
  });

  var data = {
    UserName: "",
    Password: "",
  };

  // return API.post('JWTAuth/Authenticate', data)
  //   .then((response) =>
  //     response.data
  //   ).catch((error) => {
  //     alert(error);
  //   });

  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiIiwibmJmIjoxNjk5MzU0NTU5LCJleHAiOjE2OTkzNTUxNTksImlhdCI6MTY5OTM1NDU1OX0.pUx1-g40PQkp_yiNGoXz-qejKMnJx8c-W8aFPCATgNo";
}

export default function Login(props) {
  const [company, setCompany] = useState(props.comp);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [themeColor, setThemeColor] = useState(loadThemeColor());
  const [policyHolderInfo, setPolicyHolderInfo] = useState("");

  const navigate = useNavigate();

  function loadThemeColor() {
    const icons = {
      ["Insurance-world"]: "#134C86",
      ["Aviva"]: "#59c247",
      ["DLG"]: "#a056d2",
      ["Bark"]: "#111637",
      ["Zebra"]: "#f9f8f4",
    };
    return props?.comp != undefined ? icons[props?.comp] : "#134C86";
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor,
      },
    },
  });

  const getUserDetails = async (event) => {
    event.preventDefault();
    //app-selfserve-np-eus.ngsdevapps.net/GetUser
    // axios.get(`https://apim-selfserve-np-eus.azure-api.net/internal/GetUser` ,
    https: axios
      .get(`https://app-selfserve-np-eus.ngsdevapps.net/GetUser`, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          //  'API-Key' : '8ae2dd27eff54afdab5ecc3b0eb90aa1'
        },
        params: {
          userName: username,
          password: password,
        },
      })
      .then((res) => {
        const user = res.data;
        navigate("/myprofile", { state: user });
        //this.setState({ user.InitialInformation });
      })
      .catch((error) => {
        // error is handled in catch block
        if (error.response) {
          // status code out of the range of 2xx
          console.log("Data :", error.response.data);
          console.log("Status :" + error.response.status);
          alert("Incorrect username or password");
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Error on setting up the request
          console.log("Error", error.message);
        }
      });
  };

  const navigateToMyProfile = async (event) => {
    event.preventDefault();
    const users = jsonData["Users"];
    users.forEach((element) => {
      if (username === element.InitialInformation.Username) {
        if (password === element.InitialInformation.Password) {
          //setPolicyHolderInfo(element.InitialInformation);
          console.log(element.InitialInformation);
          navigate("/myprofile", { state: element.InitialInformation });
        } else {
          alert("Incorrect password");
        }
      }
    });
  };

  function getCompanyCode(siteCode) {
    const icons = {
      ["Insurance-world"]: "IW",
      ["Aviva"]: "AV",
      ["DLG"]: "DLG",
      ["Bark"]: "BK",
      ["Zebra"]: "ZB",
      ["CLSS-Engage"]: "END", //CLSS Engage [Dev] (SID: 630-910-525)
    };
    return icons[siteCode];
  }

  let requestBody = '{"CompanyId" : "' + getCompanyCode("CLSS-Engage") + '"}';

  const registerClaim = async (selectedCompany) => {
    //axios.post()
    axios({
      method: "post",
      // url:'https://apim-selfserve-np-eus.azure-api.net/internal/api/InitialInformation/ApplicationUrl/companyId',
      // url: "https://ce-app-service-demo.ngsdevapps.net/api/InitialInformation/ApplicationUrl/companyId",
      url: 'https://app-selfserve-np-eus.ngsdevapps.net/api/InitialInformation/ApplicationUrl/companyId',
      data: requestBody,
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        // 'API-Key' : '8ae2dd27eff54afdab5ecc3b0eb90aa1'
      },
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        LaunchSelfServeApplication(response.data);
      })
      .catch(function (error) {
        alert(error?.response?.data);
      });
  };

  const LaunchSelfServeApplication = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: "primary.main" }}>
        <Header2
          title={title}
          subtitle={subTitle}
          themeColor={themeColor}
          // setThemeColor={setThemeColor}
          company={company}
        />
        <Paper
          sx={{
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            padding: 2,
            minHeight: 400,
          }}
        >
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 6,
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={getUserDetails}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>

              {/* Render the CompanySelect component and pass the registerClaim function */}
              <CompanySelect registerClaim={registerClaim} />
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
