import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./default.css";
import location from "../images/active_arrow_up.png";
import signal from "../images/single.png";
import network from "../images/network.png";
import battery from "../images/battery.png";
import home from "../images/home.png";
import arrowBack from "../images/arrow_back.png";
import notifications from "../images/Notifications none.png";
import expandMore from "../images/expand_more.png";
import arrowRightLight from "../images/arrow_right_light.png";
import arrowRightDark from "../images/arrow_right_dark.png";
import { setAuthToken } from "../helpers/setAuthToken";
import { useLocation } from "react-router-dom";

export default function ClaimFiling() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  let userPreferredLanguage = data.insuredLanguage ? data.insuredLanguage : "";
  const moveBack = () => {
    navigate("/MyProfile", { state: data });
    //alert('Hi');
  };
  //const [jwtToken , setJwtToken] = useState(()=> createJwtToken());
  
  let requestBody =
    '{"Type": "Residential",' +
    '"HeadOfficeCompanyID" : "",' +
    '"CompanyID" : "' +
    data.companyCode +
    '",' +
    '"FlatDeductible" : "' +
    GetAttributeValue(data.flatDeductible) +
    '",' +
    '"ExternalSystemCompanyCode" : "",' +
    '"CompanyName" : "' +
    GetAttributeValue(data.companyName) +
    '",' +
    '"Phone":"",' +
    '"BuiltYear":"1976",' +
    '"InsuredCompanyName": "",' +
    '"InsuredTitle": "' +
    GetAttributeValue(data.title) +
    '",' +
    '"InsuredFirstName":"' +
    GetAttributeValue(data.firstName) +
    '",' +
    '"InsuredLastName":"' +
    GetAttributeValue(data.lastName) +
    '",' +
    '"InsuredEmail": "' +
    GetAttributeValue(data.email) +
    '",' +
    '"InsuredTitle2": "",' +
    '"InsuredFirstName2": "",' +
    '"InsuredLastName2": "",' +
    '"InsuredHomePhone": "' +
    GetAttributeValue(data.phone1) +
    '",' +
    '"InsuredBusinessPhone": "",' +
    '"InsuredMobilePhone": "",' +
    '"InsuredOtherPhone": "",' +
    '"InsuredOtherPhoneLabel": "Other",' +
    '"InsuredPreferredPhone": "None",' +
    '"InsuredLanguage": "' +
    GetAttributeValue(userPreferredLanguage) +
    '",' +
    '"PolicyNumber": "' +
    GetAttributeValue(data.policyNumber) +
    '",' +
    '"PolicyStartDate": "' +
    GetAttributeValue(data.policyStartDate) +
    '",' +
    '"PolicyEndDate": "' +
    GetAttributeValue(data.policyEndDate) +
    '",' +
    '"PolicyTimesRenewed": "",' +
    '"InsuredAddress": {' +
    '"Country": "' +
    GetNestedAttributeValue(data.insuredAddress , "country") +
    '",' +
    '"State": "' +
    GetNestedAttributeValue(data.insuredAddress , "state") +
    '",' +
    '"Line1": "' +
    GetNestedAttributeValue(data.insuredAddress , "line1") +
    '",' +
    '"City": "' +
    GetNestedAttributeValue(data.insuredAddress , "city") +
    '",' +
    '"Island": "' +
    GetNestedAttributeValue(data.insuredAddress , "island") +
    '",' +
    '"ZipCode": "' +
    GetNestedAttributeValue(data.insuredAddress , "zipCode") +
    '"},' +
    '"LossAddress": {' +
    '"Country": "' +
    GetNestedAttributeValue(data.lossAddress , "country") +
    '",' +
    '"State": "' +
    GetNestedAttributeValue(data.lossAddress , "state") +
    '",' +
    '"Line1": "' +
    GetNestedAttributeValue(data.lossAddress , "line1") +
    '",' +
    '"Line2": "' +
    GetNestedAttributeValue(data.lossAddress , "line2") +
    '",' +
    '"City": "' +
    GetNestedAttributeValue(data.lossAddress , "city") +
    '",' +
    '"ZipCode": "' +
    GetNestedAttributeValue(data.lossAddress , "zipCode") +
    '",' +
    '"Latitude": "' +
    GetNestedAttributeValue(data.lossAddress , "latitude") +
    '",' +
    '"Longitude": "' +
    GetNestedAttributeValue(data.lossAddress , "longitude") +
    '"},' +
    '"LossDate": "' +GetAttributeValue(data.lossDate)+'",'+
    '"LossType": "' +GetAttributeValue(data.lossType)+'",'+
    '"LossDesc": "' +GetAttributeValue(data.lossDesc)+'"'+
    "}";
  //const [appLaunchUrl, setAppLaunchUrl] = useState([]);
  const LaunchSelfServeApplication = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  function GetNestedAttributeValue( object , attributeName){
    if(object == null || object== undefined) return "";
    return object[attributeName]
  }
  function GetAttributeValue( value){
    if(value == null || value== undefined) return "";
    return value;
  }
  const params = new URLSearchParams({
    grant_type: "client_credentials",
    client_id: "4bad7f43-31c9-4c98-83fb-4a4014368d9c",
    client_secret: "Jb08Q~9DvIGVcU6ZI2bF2jGEanahjsTk7EBSYcKJ",
    "scope:api": "//b9fb7f24-40f1-4ccb-a00a-ea7892fd0599/.default",
  }).toString();

  function createJwtToken() {
    //get authentication token

    var formBody = [];
    formBody.push("grant_type" + "=" + "client_credentials");
    formBody.push("client_id" + "=" + "4bad7f43-31c9-4c98-83fb-4a4014368d9c");
    formBody.push(
      "client_secret" + "=" + "Jb08Q~9DvIGVcU6ZI2bF2jGEanahjsTk7EBSYcKJ"
    );
    formBody.push("scope" + "=" + ".default");
    //formBody.push('code' +'=' +'code');

    formBody = formBody.join("&");

    /*axios({method :'post' ,
            url : "https://login.microsoftonline.com/0ed8fb42-586c-4cfb-9fc3-b310360dfdd3/oauth2/v2.0/token",
            data : formBody,
            headers:{
                    'Content-Type': 'application/x-www-form-urlencoded'
                   }        
            }).then(response => {
                setAuthToken(response.data.token);
        });*/

    axios
      .post(
        "https://login.microsoftonline.com/0ed8fb42-586c-4cfb-9fc3-b310360dfdd3/oauth2/v2.0/token",
        formBody,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then((response) => {
        setAuthToken(response.data.token);
      });
  }

  const registerClaim = async () => {
    /*const response = await fetch('https://localhost:7190/api/InitialInformation/ApplicationUrl',
                                {
                                    method:'POST',
                                    body : requestBody,
                                    headers:{'Content-type' : 'application/json',
                                             'Accept': 'application/json',
                                             'Access-Control-Allow-Origin': 'http://localhost:3000',
                                             'Access-Control-Allow-Credentials': 'true'
                                            }
                                    
                                }).then((response) => response.json())
                                .then((data) => setAppLaunchUrl(data));
                                alert(appLaunchUrl);


                                var axios = require('axios');*/

    //createJwtToken();
    //console.log(JSON.stringify(requestBody));
    //axios.defaults.headers.common["Authorization"] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImtXYmthYTZxczh3c1RuQndpaU5ZT2hIYm5BdyIsImtpZCI6ImtXYmthYTZxczh3c1RuQndpaU5ZT2hIYm5BdyJ9.eyJhdWQiOiJhcGk6Ly9iOWZiN2YyNC00MGYxLTRjY2ItYTAwYS1lYTc4OTJmZDA1OTkiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8wZWQ4ZmI0Mi01ODZjLTRjZmItOWZjMy1iMzEwMzYwZGZkZDMvIiwiaWF0IjoxNzA3Mzc2ODIwLCJuYmYiOjE3MDczNzY4MjAsImV4cCI6MTcwNzM4MDcyMCwiYWlvIjoiRTJWZ1lGajE0RTNCdnZUay9oY3VQTmVYUGplU0JBQT0iLCJhcHBpZCI6IjRiYWQ3ZjQzLTMxYzktNGM5OC04M2ZiLTRhNDAxNDM2OGQ5YyIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzBlZDhmYjQyLTU4NmMtNGNmYi05ZmMzLWIzMTAzNjBkZmRkMy8iLCJvaWQiOiJkOTVhYjQzZC04NmI0LTQ4YjctOTMxNC02ZWM3OTU1ZmFmMjciLCJyaCI6IjAuQVJ3QVF2dllEbXhZLTB5Znc3TVFOZzM5MHlSXy03bnhRTXRNb0FycWVKTDlCWm5PQUFBLiIsInJvbGVzIjpbIlBvbGljeUhvbGRlciJdLCJzdWIiOiJkOTVhYjQzZC04NmI0LTQ4YjctOTMxNC02ZWM3OTU1ZmFmMjciLCJ0aWQiOiIwZWQ4ZmI0Mi01ODZjLTRjZmItOWZjMy1iMzEwMzYwZGZkZDMiLCJ1dGkiOiJDUVpEb3NodUMwU0RDbnQyZExjWEFBIiwidmVyIjoiMS4wIn0.qf-0pJmRQygdINqbYsdBsSM3blArLcMmENTyEigPzsBBHQ-d8nolp0af9agJ3qPMsExM54VlMGk-jYxvWbFcY6DMmQYFeRQ9B3t8aFN1n7p2pDgM1hcLOcH8D_a-BhDxJxlqjh99dmeIz9MT2UriIN9xi5tWt2nTTzbefnQ-0nxw0-ZusS8Dpva-dJFaeedNhMUUenJpZ0jiAKXLuiXOv7mTqmEPSzx5UjyZk4ED34njP1mKJz9CHmomlbmAoS_oNfBt_-wsPVuqL7xsRzVvm-KvhBIZUhdvXXRCovH3q6f3KyRW54omWvapHV1AZk9SwsuDUeuLvVIusG5bulakPw';
    //alert(data);
    
    axios({
      method: "post",
      url: "https://app-selfserve-np-eus.ngsdevapps.net/api/InitialInformation/ApplicationUrl",
      //url :'https://apim-mica-sss-test-eastus.azure-api.net/internal/api/InitialInformation/ApplicationUrl',
      data: requestBody,
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        //,'API-Key' : '8ae2dd27eff54afdab5ecc3b0eb90aa1'
        //'Access-Control-Allow-Origin': 'http://localhost:3000',
        //'Access-Control-Allow-Credentials': 'true'
      },
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        LaunchSelfServeApplication(response.data);
      })
      .catch(function (error) {
        alert(error?.response?.data);
      });
      //console.log('Request payload '+requestBody);
  };
  return (
    <>
      <div class="Status_bar">
        <div class="time">
          <ul>
            <li>9:40</li>
            <li>
              <img src={location} width="14" />
            </li>
          </ul>
        </div>
        <div class="network text-align-right">
          <ul>
            <li>
              <img src={signal} width="20" />
            </li>
            <li>
              <img src={network} width="24" />
            </li>
            <li>
              <img src={battery} width="28" />
            </li>
          </ul>
        </div>
      </div>

      <div class="trailing_icons">
        <ul>
          <li onClick={moveBack}>
            <img src={arrowBack} width="24" />
          </li>
          <li>
            {" "}
            <h4> File a claim </h4>{" "}
          </li>
          <li>
            <img src={notifications} width="24" />
          </li>
        </ul>
      </div>

      <div class="content_box">
        <div class="claim_row">
          <div class="claim_head">
            <h4>Product Type</h4>
          </div>

          <div class="claim_content">
            <div class="loop_claim">
              <ul>
                <li> Policy Plan </li>
                <li class="text-align-right"> Home Insurance</li>
              </ul>
            </div>
            <div class="loop_claim">
              <ul>
                <li> Policy No. </li>
                <li class="text-align-right"> {data.policyNumber}</li>
              </ul>
            </div>
            <div class="loop_claim">
              <ul>
                <li> Status </li>
                <li class="text-align-right"> {data.policyStatus}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="claim_row">
          <div class="claim_head">
            <h4>Policy Date</h4>
          </div>

          <div class="claim_content">
            <div class="loop_claim">
              <ul>
                <li> Start Date</li>
                <li class="text-align-right"> {data.policyStartDate}</li>
              </ul>
            </div>
            <div class="loop_claim">
              <ul>
                <li> Expiry Date </li>
                <li class="text-align-right"> {data.policyEndDate}</li>
              </ul>
            </div>
            <div class="loop_claim">
              <ul>
                <li> Cover limit </li>
                <li class="text-align-right"> {data.coverLimit}</li>
              </ul>
            </div>
            <div class="loop_claim">
              <ul>
                <li> Cover Type </li>
                <li class="text-align-right">
                  {" "}
                  Buildings with Accidental Damage
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="claim_row">
          <div class="claim_head ">
            <label for="item-3" class="toggle">
              <h4 class="dflex_claim">
                Insured Address{" "}
                <span>
                  <img src={expandMore} />
                </span>
              </h4>
            </label>
          </div>
          <input type="checkbox" name="one" id="item-3" class="hide-input" />
          <div class="claim_content toggle-el">
            <div class="loop_claim">
              <p>{GetNestedAttributeValue(data.insuredAddress ,"line1")}</p>
              <p> {GetNestedAttributeValue(data.insuredAddress , "city")} </p>
              <p> {GetNestedAttributeValue(data.insuredAddress , "state")} </p>
              <p> {GetNestedAttributeValue(data.insuredAddress , "zipCode")} </p>
              <p> {GetNestedAttributeValue(data.insuredAddress , "country")} </p>
            </div>
          </div>
        </div>

        <div class="file_claim_btngrp">
          <button
            class="btn_primary"
            type="button"
            value="submit"
            onClick={registerClaim}
          >
            {" "}
            File New Claim
            <span>
              <img src={arrowRightLight} />
            </span>
          </button>

          <button
            class="btn_secondary"
            type="button"
            onclick="location.href='#';"
            value="submit"
          >
            {" "}
            View existing claims
            <span>
              <img src={arrowRightDark} />
            </span>
          </button>
        </div>

        <div class="footer_dark">
          <p>
            Copyright © 2023 <a href="#">Insuranceworld.com</a> <br /> All
            rights reserved
          </p>
        </div>
      </div>
    </>
  );
}

function getCompanyName(companyCode) {
  const companiesList = {
    "AI" : ["Alphabet Insurance (SID: 630-910-525)"],
    "AV" : ["Aviva (SID: 630-910-525)"],
    "BK" : ["CLSS Bark Insurance [MO] (SID: 630-910-525)"],
    "DLG": ["DLG Insurance (SID: 630-910-525)"],
    "EC" : ["Engage Claims (SID: 630-910-525)"],
    "END": ["CLSS Engage [Dev] (SID: 630-910-525)"], 
    "IW" : ["Insurance World (IW) (SID: 630-910-525)"], 
    "ZB" : ["CLSS Zebra Cover ?? [Dev] (SID: 174-388-629)"]
  };
  return companiesList[companyCode];
}
