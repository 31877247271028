import React from "react";
import { Button } from "@mui/material";

const CompanySelect = ({ registerClaim }) => {
  return (
    <>
      <Button
        type="button"
        className="btn_primary"
        onClick={registerClaim} // Call registerClaim to make claim and clear input
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
      >
        Make a claim
      </Button>
    </>
  );
};

export default CompanySelect;
