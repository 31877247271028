import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { HeaderProfile } from '../stories/HeaderProfile'
import { useState } from "react";
import location from '../images/active_arrow_up.png'
import signal from '../images/single.png'
import network from '../images/network.png'
import battery from '../images/battery.png'
import home from '../images/home.png'
import copyWriting from '../images/copy-writing.png'
import carInsurance from '../images/car-insurance.png'
import world from '../images/world.png'
import family from '../images/family.png'
import retirement from '../images/retirement.png'
import track from '../images/track.png'
import claim from '../images/claim.png'
import image from '../images/image.png'
import menu from '../images/menu.png'
import notifications from '../images/Notifications none.png'
import profilePhoto from '../images/user_profile_pic.png'
import profileMail from '../images/mail-pro.png' 
import profilePhone from '../images/phone_iphone.png' 
import './default.css'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";





export default function MyProfile(route , navigation) {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [themeColor, setThemeColor] = useState('#134C86');
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  

  // The object
var obj = {
  a: 5,
  b: function (data) {
    navigate('/ClaimFiling' ,  {state : data});
    //return data*data;
  }
};
  

  const moveBack = ()=>{
    navigate('/' , {state : data});
    //alert('Hi');
  }
  const onClick = () => {
   // navigate('/ClaimFiling' ,  {state : data});
   /*var json = JSON.stringify(obj, function(key, value) {
    if (typeof value === "function") {
      return "/Function(" + value.toString() + ")/";
    }
    return value;
  });


    alert(eventJson.b);
    var obj2 = JSON.parse(json, function(key, value) {
      if (typeof value === "string" &&
          value.startsWith("/Function(") &&
          value.endsWith(")/")) {
            alert(value);
        value = value.substring(10, value.length - 2);
        return (0, eval)("(" + value + ")");
      }
      return value;
    });
    alert(data);
    obj2.b(data);*/
    navigate('/ClaimFiling' ,  {state : data});

  }
 
  


  return (
    <>
    
     <div class="Status_bar">
      <div class="time">
        <ul>
          <li>9:40</li>
          <li><img src={location} width="14"/></li>
        </ul>
      </div>
      <div class="network text-align-right">
        <ul>
          <li><img src={signal} width="20"/></li>
          <li><img src={battery} width="24"/></li>
          <li><img src={network} width="28"/></li>
        </ul>
      </div>
    </div>

    
    <div class="trailing_icons">
      <ul>
        <li onClick={moveBack}><img src={menu} width="24"/></li>
        <li>
          <h4> My Profile </h4>
        </li>
        <li><img src={notifications} width="24"/></li>
      </ul>
    </div>

    
    <div class="user_profile">
      <div class="user_pic">
        <img src={profilePhoto}/>
      </div>

      <div class="user_details">
        <ul>
          <li>
            <h1>{data.firstName} {data.lastName}</h1>
          </li>
          <li class="user_mail"><span><img src={profileMail} width="14"/></span> <span> {data.username}
            </span></li>
          <li class="user_mail"><span><img src={profilePhone} width="14"/></span> <span>{data.phone1}
            </span></li>
        </ul>
      </div>
    </div>

    
    <div class="content_box">

    <div class="policy_head">
        <h2>Insurance Policy</h2>
      </div>

      
      <div class="policy_cards">

        <div class="item_card" onClick={onClick}>
          <div class="icon_box"> <img src={home} width="31"/> </div>
          <div class="text_box"> Buildings </div>
        </div>

        <div class="item_card" onclick="location.href='file_claim.html';">
          <div class="icon_box"> <img src={copyWriting} width="31"/> </div>
          <div class="text_box"> Contents </div>
        </div>

        <div class="item_card" onclick="location.href='file_claim.html';">
          <div class="icon_box"> <img src={carInsurance} width="31"/> </div>
          <div class="text_box"> Motor </div>
        </div>

        <div class="item_card" onclick="location.href='file_claim.html';">
          <div class="icon_box"> <img src={world} width="31"/> </div>
          <div class="text_box"> Travel </div>
        </div>

        <div class="item_card" onclick="location.href='file_claim.html';">
          <div class="icon_box"> <img src={family} width="31"/> </div>
          <div class="text_box"> Family </div>
        </div>

        <div class="item_card" onclick="location.href='file_claim.html';">
          <div class="icon_box"> <img src={retirement} width="31"/> </div>
          <div class="text_box"> Retirement </div>
        </div>

      </div>

      

      
      <div class="news_update">
        <div class="news_row border-bottom">
          <div class="news_img">
            <img src={image}/>
          </div>
          <div class="news_content">
            <h2>Home insurance...</h2>
            <p>Lorem Ipsum is simply dummy text of printing and industry.</p>
            <p> <a href="#">Read more</a></p>
          </div>
        </div>
        <div class="news_row">
          <div class="news_img">
            <img src={image}/>
          </div>
          <div class="news_content">
            <h2>Home insurance...</h2>
            <p>Lorem Ipsum is simply dummy text of printing and industry.</p>
            <p> <a href="#">Read more</a></p>
          </div>
        </div>
      </div>

      
      <div class="footer_dark">
        <p>Copyright © 2023 <a href="#">Insuranceworld.com</a> <br/> All rights reserved</p>
      </div>

    </div>



    </>
  )
}
